import { takeEvery, all, takeLatest, takeLeading } from 'redux-saga/effects'
import * as types from 'Actions/types'

import * as app from './app'
import * as attendance from './attendance'
import * as auth from './auth'
import * as authorised from './authorised'
import * as cache from './cache'
import * as employee from './employee'
import * as entity from './entity'
import * as lookup from './lookup'
import * as repairOrder from './repairOrder'
import * as shell from './shell'
import * as table from './table'
import * as user from './user'
import * as vehicle from './vehicle'
import * as workOrder from './workOrder'

const watchApp = [
  takeEvery(types.APP_CHANGE_LANGUAGE, app.changeLanguageSaga),
  takeEvery(types.APP_GOTO_URL, app.gotoUrlSaga),
  takeEvery(types.APP_START_VERSION_CHECK, app.startVersionCheckSaga),
]

const watchAttendance = [
  takeEvery(types.ATTENDANCE_CHECK_TOKEN, attendance.checkTokenSaga),
  takeEvery(types.ATTENDANCE_GET, attendance.getList),
  takeEvery(types.ATTENDANCE_REGISTER, attendance.register),
  takeEvery(types.ATTENDANCE_START_REFRESHING, attendance.startRefreshing),
]

const watchAuth = [
  takeEvery(types.AUTH_CHANGE_PASSWORD, auth.changePasswordSaga),
  takeEvery(types.AUTH_CHECK_PASSWORD, auth.checkPasswordSaga),
  takeEvery(types.AUTH_COMPANY_GET, auth.getCompanySaga),
  takeEvery(types.AUTH_COMPANY_PUT, auth.putCompanySaga),
  takeEvery(types.AUTH_GET_COMPANIES, auth.getCompaniesSaga),
  takeEvery(types.AUTH_GET_QRCODE, auth.getQrCodeSaga),
  takeEvery(types.AUTH_SESSION_INIT, auth.initSessionSaga),
  takeEvery(types.AUTH_SIGNIN, auth.signinSaga),
  takeEvery(types.AUTH_SIGNOUT, auth.signoutSaga),
  takeEvery(types.AUTH_SWITCH_COMPANY, auth.switchCompany),
  takeEvery(types.AUTH_TWOFACTOR_ENABLE, auth.twoFactorEnableSaga),
  takeEvery(types.AUTH_VALIDATE_TOKEN, auth.validateTokenSaga),
]

const watchAuthorised = [
  takeEvery(types.AUTHORISED_CREATE, authorised.createSaga),
  takeEvery(types.AUTHORISED_GET_BRANDS, authorised.getBrandsSaga),
  takeEvery(types.AUTHORISED_GET_LIST, authorised.getListSaga),
  takeEvery(types.AUTHORISED_REMOVE, authorised.removeSaga),
]

const watchCache = [
  takeLeading(types.CACHE_LOAD_LOOKUPS, cache.loadLookupsSaga),
]

const watchEmployee = [takeEvery(types.EMPLOYEE_GET_QRCODE, employee.getQrCode)]

const watchEntity = [
  takeEvery(types.ENTITY_GET, entity.getSaga),
  takeEvery(types.ENTITY_PUT, entity.updateSaga),
]

const watchLookup = [
  takeEvery(types.LOOKUP_LOAD_LANGUAGES, lookup.loadLanguagesSaga),
]

const watchRepairOrder = [
  takeEvery(types.REPAIR_ORDER_UPLOAD, repairOrder.uploadSaga),
]

const watchShell = [
  takeEvery(types.SHELL_CONFIRM, shell.confirmSaga),
  takeEvery(types.SHELL_MESSAGE, shell.messageSaga),
]

const watchTable = [takeLatest([types.TABLE_LOAD_INIT], table.loadSaga)]

const watchUser = [
  takeEvery(types.AUTH_USER_GET, user.getUserSaga),
  takeEvery(types.AUTH_USER_PUT, user.putUserSaga),
  takeEvery(types.AUTH_USER_PUT_THEME, user.putUserThemeSaga),
]

const watchVehicle = [
  takeEvery(types.VEHICLE_GET_INSPECTIONS, vehicle.getInspectionsSaga),
]

const watchWorkOrder = [takeEvery(types.WORK_ORDER_ADD, workOrder.addSaga)]

export default function* rootSaga() {
  yield all([
    ...watchApp,
    ...watchAttendance,
    ...watchAuth,
    ...watchAuthorised,
    ...watchCache,
    ...watchEmployee,
    ...watchEntity,
    ...watchLookup,
    ...watchRepairOrder,
    ...watchShell,
    ...watchTable,
    ...watchUser,
    ...watchVehicle,
    ...watchWorkOrder,
  ])
}
