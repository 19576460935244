import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import { loadSucceed, setLoading, setSkip } from 'Actions/table'
import { getEntity } from 'Root/app/IntlProvider'

export function* loadSaga(action) {
  yield put(setLoading(true))
  try {
    const state = yield select()
    if (state.table.data.selected.length > 0) {
      // Prevent reorder sorting
      return
    }
    const entities = yield call(getEntity, state.table.info.entityType, true)
    const { searchText = '' } = state.table.info
    const { ok, error, data } = yield http.get(
      `/${entities}?skip=${action ? action.skip || 0 : 0}&limit=100${
        action.filter ? '&' + action.filter.substring(1) : ''
      }&q=${searchText}`
    )
    if (ok) {
      yield put(loadSucceed(data))
      if (action && (action.skip || 0) !== state.table.info.skip) {
        yield put(setSkip(action.skip || 0))
      }
    } else if (error) {
      yield call(globalError, data)
    }
  } catch (err) {
    console.error('Table loading error', err)
  } finally {
    yield put(setLoading(false))
  }
}
