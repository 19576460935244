import { blue } from '@material-ui/core/colors'
import is from 'is_js'

let language =
  window.localStorage.getItem('language') ||
  (navigator.language || navigator.userLanguage || []).split('-')[0]
language = ['nl', 'en'].includes(language) ? language : 'nl'

/* colors
  https://flatuicolors.com
*/
export default {
  app: {
    countries: {},
    frame: true,
    language,
    languages: {},
    navbarDocked:
      is.mobile() || window.innerWidth <= 600
        ? false
        : JSON.parse(window.localStorage.getItem('docked')) === null
        ? true
        : JSON.parse(window.localStorage.getItem('docked')),
    navbarOpen:
      is.mobile() || window.innerWidth <= 600
        ? false
        : JSON.parse(window.localStorage.getItem('docked')) === null
        ? true
        : JSON.parse(window.localStorage.getItem('docked')),
    panels: {},
    newVersion: false,
    version: null,
  },
  attendance: {
    list: [],
  },
  auth: {
    companies: [],
    company: {
      name: '',
    },
    info: {
      authenticated: undefined,
      initialized: true,
      previewMode: false,
      subscription: '',
      subscriptionEnded: false,
    },
    twoFactor: {
      code: '',
      url: '',
    },
    user: {
      id: '',
      name: '',
      email: window.localStorage.getItem('email'),
      language: window.localStorage.getItem('language'),
      role: 'user',
      twoFactorEnabled: false,
      settings: {},
    },
  },
  authorised: {
    brands: [],
    list: [],
  },
  cache: {
    lookups: {},
  },
  entity: {
    attachments: [],
    data: {},
    info: {
      contentOverflowX: 'hidden',
      contentOverflowY: 'auto',
      editForm: false,
      editMode: false,
      entityType: '',
      id: '',
      loading: true,
      prevLocation: '',
      tabCaption: '',
      tabCount: 0,
    },
  },
  shell: {
    message: {
      duration: 5000,
      level: '',
      text: '',
      visible: false,
    },
  },
  table: {
    data: {
      total: false,
      rows: [],
      selected: [],
    },
    filters: {},
    info: {
      entityType: '',
      filter: 'none',
      firstload: false,
      loading: false,
      searchText: '',
      scrollTop: null,
      skip: 0,
    },
  },
  theme: {
    palette: {
      accent: '#d35400',
      repairroom: {
        attendance: '#00a8ff',
        company: '#34495e',
        employee: '#00a8ff',
        //employee: '#6c5ce7',
        inspection: '#e67e22',
        relocation: '#e74c3c',
        user: '#bdc3c7',
        vehicle: '#1abc9c',
      },
      primary: {
        contrastText: 'rgba(255, 255, 255, 1)',
        dark: blue[700],
        light: blue[300],
        main: blue[500],
      },
      secondary: {
        main: '#d35400',
      },
      type: window.localStorage.getItem('palette') || 'light',
    },
    typography: {
      useNextVariants: true,
    },
  },
  vehicle: {
    inspections: {
      list: [],
    },
  },
}
