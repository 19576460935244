import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const dataReducer = createReducer(initialState.table.data, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.table.data }),
  [types.TABLE_DESELECT_ITEM]: (state, action) => ({
    ...state,
    selected: state.selected.filter((item) => item !== action.id),
  }),
  [types.TABLE_INSERT_ITEM]: (state, action) => {
    return { ...state, rows: [action.data, ...state.rows] }
  },
  [types.TABLE_LOAD]: (state, action) => ({
    ...state,
    rows: action.data.rows || [],
    filters: action.data.filters || {},
    total: action.data.total,
  }),
  [types.TABLE_RESET]: () => ({ ...initialState.table.data }),
  [types.TABLE_SELECT_ITEM]: (state, action) => {
    const { id, multiSelect } = action
    let selected = [...state.selected, id]
    if (!multiSelect) {
      selected = [id]
    }
    const selectedSorted = state.rows
      .filter((item) => selected.includes(item.id))
      .map((item) => item.id)
    return { ...state, selected: selectedSorted }
  },
  [types.TABLE_SET_INITDATA]: () => ({ ...initialState.table.data }),
  [types.TABLE_SET_SELECTED]: (state, action) => ({
    ...state,
    selected: action.selected,
  }),
  [types.TABLE_SET_TYPE]: () => ({ ...initialState.table.data }),
  [types.TABLE_SORT_SELECTED]: (state) => {
    const dataWithoutSelected = state.rows.filter(
      (item) => !state.selected.includes(item.id)
    )
    const dataSelected = state.rows.filter((item) =>
      state.selected.includes(item.id)
    )
    return { ...state, rows: [...dataSelected, ...dataWithoutSelected] }
  },
})

const infoReducer = createReducer(initialState.table.info, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.table.info }),
  [types.TABLE_INSERT_ITEM]: (state, action) => {
    if (state.entityType === action.entityType) {
      return { ...state, scrollTop: 0 }
    }
    return state
  },
  [types.TABLE_RESET]: (state) => {
    const { entityType } = state
    return { ...initialState.table.info, entityType }
  },
  [types.TABLE_SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.loading,
    firstload: !state.firstload ? !action.loading : state.firstload,
  }),
  [types.TABLE_SET_SCROLLTOP]: (state, action) => ({
    ...state,
    scrollTop: action.scrollTop,
  }),
  [types.TABLE_SET_SEARCH_TEXT]: (state, action) => ({
    ...state,
    searchText: action.searchText,
  }),
  [types.TABLE_SET_SKIP]: (state, action) => ({
    ...state,
    skip: action.value,
  }),
  [types.TABLE_SET_TYPE]: (_, action) => ({
    ...initialState.table.info,
    entityType: action.entityType,
  }),
})

export default combineReducers({
  data: dataReducer,
  info: infoReducer,
})
