export const entity = {
  single: {
    attendance: 'attendance',
    employee: 'employee',
    inspection: 'inspection',
    relocation: 'relocation',
    vehicle: 'vehicle',
  },
  plural: {
    attendance: 'attendances',
    employee: 'employees',
    inspection: 'inspections',
    relocation: 'relocations',
    vehicle: 'vehicles',
  },
}

export const language = {
  en: 'en',
  nl: 'nl',
}

export const messageLevel = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
}

export const role = {
  admin: 'admin',
  room: 'room',
  user: 'user',
}

export const palette = {
  dark: 'dark',
  light: 'light',
}
