import React from 'react'
import withWidth from './withWidth'
import { isWidthDown } from '@material-ui/core/withWidth'

const withMobileDialog = (options = {}) => (Component) => {
  const { breakpoint = 'sm' } = options

  function WithMobileDialog(props) {
    return (
      <Component fullScreen={isWidthDown(breakpoint, props.width)} {...props} />
    )
  }

  return withWidth(WithMobileDialog)
}

export default withMobileDialog
