import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import { messageLevel } from 'Utils/constants'
import { showMessage } from 'Actions/shell'

export function* uploadSaga(action) {
  const { payload } = action
  const { resolve, file } = payload
  const { ok, error, data } = yield http.post('/repair-orders', file)
  if (ok) {
    return yield call(resolve, true)
  } else if (error) {
    let message = 'Onbekende fout'
    switch (data.error) {
      case 'Invalid file':
        message = 'Geen geldig bestand'
        break
      case 'Order exists':
        message = 'Opdrachtbon is al ingelezen'

        break
    }
    yield put(showMessage(message, messageLevel.error))
    //yield call(globalError, data)
  }
  yield call(resolve, false)
}
