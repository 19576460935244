import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import reducers from './reducers'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'
import history from 'Utils/history'

const sagaMiddleware = createSagaMiddleware()

const middleware =
  process.env.NODE_ENV !== 'production'
    ? [require('redux-immutable-state-invariant').default(), sagaMiddleware]
    : [sagaMiddleware]

const configureStore = () => {
  const store = createStore(
    reducers(history),
    composeWithDevTools(applyMiddleware(...middleware))
  )
  sagaMiddleware.run(sagas)
  return { store }
}

export default configureStore
