import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({
  isAuthenticated,
  isEnded,
  component: Component,
  ...rest
}) => {
  let redirect = ''
  if (rest.location && rest.location.pathname !== '/') {
    redirect = `/?redirect=${rest.location.pathname}`
  }
  const renderRoute = (props) => {
    if (!isAuthenticated) {
      return <Redirect to={`/signin${redirect}`} />
    }
    return <Component {...props} />
  }
  return <Route {...rest} render={renderRoute} />
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.info.authenticated,
})

export default connect(mapStateToProps)(PrivateRoute)
