import React from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import NumberFormat from 'Components/common/NumberFormat'

const styles = () => ({
  formControl: {
    width: '100%'
  }
})
const NumericField = ({
  align = 'left',
  classes,
  className,
  disabled = false,
  input,
  startAdornment = '',
  endAdornment = '',
  label,
  meta: { touched, error },
  placeholder,
  fullWidth = true,
  onInputChange,
  onlyInput,
  style,
  immediateError,
  InputLabelProps,
  ...other
}) => {
  const { name, onChange, onBlur, value, ...rest } = input
  const inputStyle = { textAlign: align, ...style }
  return (
    <FormControl
      className={classNames(fullWidth && classes.formControl)}
      error={(touched || immediateError) && !!error}
      disabled={disabled}
    >
      {!onlyInput && (
        <InputLabel shrink htmlFor={name} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <Input
        type="tel"
        style={{ height: 29 }}
        className={className}
        inputComponent={NumberFormat}
        value={value}
        startAdornment={
          startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null
        }
        endAdornment={
          endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : null
        }
        inputProps={{
          placeholder,
          onBlur,
          onChange,
          onInputChange,
          style: inputStyle,
          value,
          ...other
        }}
        {...rest}
      />
      {!onlyInput && (
        <FormHelperText>{(touched || immediateError) && error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default compose(withStyles(styles))(NumericField)
