import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import initialState from './initialState'
import { createReducer } from 'Utils/redux'
import { t } from 'Root/app/IntlProvider'

const lookupsReducer = createReducer(initialState.cache.lookups, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.cache.lookups }),
  [types.CACHE_ADD_LOOKUPS]: (state, { data }) => {
    const items = data
    for (const item in items) {
      const props = items[item]
      for (const prop in props) {
        const property = props[prop]
        if (!property.name) {
          property.name = t(`lookup.${item}.${prop}`)
        }
      }
    }
    return { ...state, ...items }
  }
})

export default combineReducers({
  lookups: lookupsReducer
})
