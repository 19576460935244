import { call, put } from 'redux-saga/effects'
import { show } from 'redux-modal'
import { http } from 'Utils/http'
import { globalError } from './shell'
import { confirmSaga } from './shell'
import { t } from 'Root/app/IntlProvider'

export function* getQrCode(action) {
  const { id } = action
  const yes = yield call(confirmSaga, {
    title: t('employee.message.qrcode.title'),
    content: t('employee.message.qrcode.content'),
  })
  if (!yes) {
    return
  }
  const { ok, error, data } = yield http.post(`/employees/${id}/qrcode`)
  if (ok) {
    yield put(show('code', data))

    console.log(data)
    //yield put(getAttendancesSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}
