import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import { messageLevel } from 'Utils/constants'

const messageReducer = createReducer(initialState.shell.message, {
  [types.SHELL_MESSAGE_SHOW]: (state, action) => ({
    ...state,
    text: action.text,
    visible: true,
    level: action.level || messageLevel.success,
    duration: action.duration || 5000
  }),
  [types.SHELL_MESSAGE_HIDE]: state => ({ ...state, visible: false })
})

export default combineReducers({
  message: messageReducer
})
