import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let RepairRoom = ({ color = '#588aba', ...props }) => (
  <SvgIconCustom viewBox="0 0 50.464 50.464" {...props}>
    <svg height={48} viewBox="0 0 48 48" width={48} {...props}>
      <g fill={color}>
        <path d="M7.655 32.34c0-.909.16-1.78.44-2.595a3.64 3.64 0 01-.084-.703c0-1.395.435-4.064 3.349-6.247l1.024-.768.14-.826-.355-.355a4.237 4.237 0 01-1.22-2.713c-.045 0-.085-.004-.13-.004-.321 0-.623.044-.925.081a20.625 20.625 0 00-.457 2.02c-3.563 2.672-4.631 6.14-4.631 8.812 0 4.165 3.05 5.343 3.05 5.343h.073a8.017 8.017 0 01-.274-2.045zM46.9 27.531c-.064-6.232-5.228-8.726-9.147-8.903-2.449-9.799-14.6-13.946-22.275-8.7.506.248.992.521 1.457.816.411-.13.835-.215 1.278-.215.8 0 1.56.23 2.225.64a11.109 11.109 0 012.676-.327c4.665 0 10.124 2.943 11.529 8.564l.579 2.316 2.384.11c.618.028 6.033.427 6.085 5.734l.02 1.98-.004.231c.275.808.429 1.668.429 2.565a7.97 7.97 0 01-.275 2.045h.247c2.726 0 2.788-3.648 2.788-3.648h1.068v-3.206c.004-.002-.595-.002-1.065-.002zM23.59 31.18c.055.378.094.765.094 1.16 0 .707-.102 1.36-.275 2.016h4.974a7.756 7.756 0 01-.274-2.016c0-.395.038-.78.094-1.16H23.59z" />
        <path d="M15.67 26.462a5.877 5.877 0 100 11.755 5.877 5.877 0 000-11.755zm0 8.491c-1.44 0-2.613-1.173-2.613-2.612s1.174-2.61 2.613-2.61 2.612 1.17 2.612 2.61-1.173 2.612-2.612 2.612zM36.124 26.462a5.877 5.877 0 100 11.755 5.877 5.877 0 000-11.755zm0 8.491c-1.44 0-2.613-1.173-2.613-2.612s1.175-2.61 2.613-2.61 2.612 1.17 2.612 2.61-1.174 2.612-2.612 2.612zM23.502 21.602l1.512-1.511-3.023-3.022.756-.756-3.022-3.022a2.137 2.137 0 00-3.022 0l-3.023 3.022a2.139 2.139 0 000 3.022l3.023 3.022 1.006-1.008-.25.253 3.021 3.021 1.511-1.51-3.022-3.022 1.511-1.511 3.022 3.022z" />
        <path d="M3.977 22.398c-1.664-7.956 6.684-9.96 11.15-5.188l2.267-2.267c-3.26-3.44-7.85-4.399-11.942-2.704-3.555 1.472-7.948 6.21-3.714 15.217.252-1.626.908-3.4 2.24-5.058z" />
      </g>
    </svg>{' '}
  </SvgIconCustom>
)

RepairRoom = pure(RepairRoom)
RepairRoom.muiName = 'SvgIcon'

export default RepairRoom
