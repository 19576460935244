import React, { Component } from 'react'
import { render } from 'react-dom'
import compose from 'recompose/compose'
import { Provider, connect } from 'react-redux'
import { MuiThemeProvider, withTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import numeral from 'numeral'
import moment from 'moment'
import 'Utils/moment/nl'
import { startVersionCheck } from 'Actions/app'

import IntlProvider from './IntlProvider'
import Message from './Message'

import IntlApp from './IntlApp'
import configureStore from '../store/configureStore'
import { validateToken } from 'Actions/auth'
import { createTheme } from 'Selectors/app'

import '!file-loader?name=[name].[ext]!../images/android-chrome-192x192.png'
import '!file-loader?name=[name].[ext]!../images/android-chrome-512x512.png'
import '!file-loader?name=[name].[ext]!../images/apple-touch-icon.png'
import '!file-loader?name=[name].[ext]!../images/browserconfig.xml'
import '!file-loader?name=[name].[ext]!../images/favicon-16x16.png'
import '!file-loader?name=[name].[ext]!../images/favicon-32x32.png'
import '!file-loader?name=[name].[ext]!../images/favicon.ico'
import '!file-loader?name=[name].[ext]!../images/mstile-150x150.png'
import '!file-loader?name=[name].[ext]!../images/safari-pinned-tab.svg'
import '!file-loader?name=[name].[ext]!../images/site.webmanifest'

import '../styles/repairroom.css'

const { store } = configureStore()

numeral.register('locale', 'nl', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'ste' : 'e'
  },
  currency: {
    symbol: '€',
  },
})
numeral.localeData('en').currency.symbol = '€'
moment.updateLocale('nl', {
  monthsShort: moment.monthsShort('-MMM-'),
  weekdaysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
})

class ThemableApp extends Component {
  componentDidMount = () => {
    this.props.validateToken()
    window.addEventListener('offline', this.handleOnlineChange)
    window.addEventListener('online', this.handleOnlineChange)
    this.props.startVersionCheck()
  }

  componentWillUnmount = () => {
    window.removeEventListener('offline', this.handleOnlineChange)
    window.removeEventListener('online', this.handleOnlineChange)
  }

  render() {
    const { noRender, theme } = this.props
    return !noRender ? (
      <IntlProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Message />
          <IntlApp />
        </MuiThemeProvider>
      </IntlProvider>
    ) : null
  }
}

const Wrapper = compose(
  withTheme,
  connect(
    (state) => ({
      language: state.app.language,
      theme: createTheme(state),
      noRender: typeof state.auth.info.authenticated === 'undefined',
    }),
    {
      startVersionCheck,
      validateToken,
    }
  )
)(ThemableApp)

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Provider store={store}>
      <Wrapper />
    </Provider>
  </MuiPickersUtilsProvider>
)
render(<App />, document.getElementById('app'))
