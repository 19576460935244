import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const attendanceReducer = createReducer(initialState.attendance, {
  [types.ATTENDANCE_GET_SUCCEED]: (state, action) => ({
    ...state,
    list: action.data,
  }),
})

export default attendanceReducer
