import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { getBrandsSucceed, getList, getListSucceed } from 'Actions/authorised'
import { globalError } from './shell'

export function* createSaga(action) {
  const { payload } = action
  const { ok, data } = yield http.post(`/authorised`, payload)
  if (ok) {
    // ok
    yield put(getList())
  } else if (data.error == 'Brand/model already exists') {
    // do nothing
  } else {
    yield call(globalError, data)
  }
}

export function* getBrandsSaga() {
  const { ok, error, data } = yield http.get(`/authorised/brands`)
  if (ok) {
    yield put(getBrandsSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getListSaga() {
  const { ok, error, data } = yield http.get(`/authorised`)
  if (ok) {
    yield put(getListSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* removeSaga(action) {
  const { id } = action
  const { noContent, data } = yield http.delete(`/authorised/${id}`)
  if (noContent) {
    // ok
    yield put(getList())
  } else {
    yield call(globalError, data)
  }
}
