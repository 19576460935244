import * as types from './types'
import { createAction } from 'Utils/redux'

export const create = createAction(types.AUTHORISED_CREATE, 'payload')
export const getBrands = createAction(types.AUTHORISED_GET_BRANDS)
export const getBrandsSucceed = createAction(
  types.AUTHORISED_GET_BRANDS_SUCCEED,
  'data'
)
export const getList = createAction(types.AUTHORISED_GET_LIST)
export const getListSucceed = createAction(
  types.AUTHORISED_GET_LIST_SUCCEED,
  'data'
)
export const remove = createAction(types.AUTHORISED_REMOVE, 'id')
