import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { showMessage } from 'Actions/shell'
import { setData, setEditMode, setId, setLoading } from 'Actions/entity'
import { globalError } from './shell'
import { getEntity, getEntityCaption, t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'

export function* getSaga(action) {
  const { id } = action
  yield put(setId(id))
  yield put(setLoading(true))
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, notFound, data } = yield http.get(`/${entity}/${id}`)
  if (ok) {
    yield put(setData(data))
  } else if (notFound) {
    yield put(setData({}))
    const item = yield call(getEntityCaption, state.entity.info.entityType)
    yield put(
      showMessage(t('entity.message.notFound', { item }), messageLevel.warning)
    )
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* updateSaga(action) {
  const { id, values } = action
  const state = yield select()
  const entity = yield call(getEntity, state.entity.info.entityType, true)
  const { ok, error, notFound, data } = yield http.put(
    `/${entity}/${id}`,
    values
  )
  if (ok) {
    console.log(data)
    yield put(setData(data))
    yield put(setEditMode(false))
  } else if (notFound) {
    const item = yield call(getEntityCaption, state.entity.info.entityType)
    yield put(
      showMessage(t('entity.message.notFound', { item }), messageLevel.warning)
    )
  } else if (error) {
    yield call(globalError, data)
  }
}
