import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let VideoOutline = props => (
  <SvgIconCustom {...props}>
    <path d="M15 8v8H5V8h10m1-2H4a1 1 0 00-1 1v10a1 1 0 001 1h12a1 1 0 001-1v-3.5l4 4v-11l-4 4V7a1 1 0 00-1-1z" />
  </SvgIconCustom>
)

VideoOutline = pure(VideoOutline)
VideoOutline.muiName = 'SvgIcon'

export default VideoOutline
