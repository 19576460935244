import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const dataReducer = createReducer(initialState.entity.data, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.entity.data }),
  [types.ENTITY_RESET_DATA]: () => ({}),
  [types.ENTITY_SET_DATA]: (_, action) => ({ ...action.data }),
  [types.ENTITY_UPDATE_DATA]: (state, { data }) => ({
    ...state,
    ...data,
  }),
})

const infoReducer = createReducer(initialState.entity.info, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.entity.info }),
  [types.ENTITY_SET_CONTENTOVERFLOWX]: (state, action) => ({
    ...state,
    contentOverflowX: action.value,
  }),
  [types.ENTITY_SET_CONTENTOVERFLOWY]: (state, action) => ({
    ...state,
    contentOverflowY: action.value,
  }),
  [types.ENTITY_SET_EDITFORM]: (state, action) => ({
    ...state,
    editForm: action.editForm,
  }),
  [types.ENTITY_SET_EDITMODE]: (state, action) => ({
    ...state,
    editMode: action.editMode,
  }),
  [types.ENTITY_SET_ID]: (state, action) => ({ ...state, id: action.id }),
  [types.ENTITY_SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.loading,
  }),
  [types.ENTITY_SET_PREVLOCATION]: (state, action) => ({
    ...state,
    prevLocation: action.prevLocation,
  }),
  [types.ENTITY_SET_TAB_CAPTION]: (state, action) => ({
    ...state,
    tabCaption: action.tabCaption,
  }),
  [types.ENTITY_SET_TAB_COUNT]: (state, action) => ({
    ...state,
    tabCount: action.tabCount,
  }),
  [types.ENTITY_SET_TYPE]: (state, action) => ({
    ...state,
    entityType: action.entityType,
  }),
})

export default combineReducers({
  data: dataReducer,
  info: infoReducer,
})
