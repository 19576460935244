import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(13)
  }
})
const Tooltip = ({ children, classes, delay = 1000, title }) => {
  if (title) {
    return (
      <MuiTooltip
        classes={{ tooltip: classes.tooltip }}
        enterDelay={delay}
        title={title}
        disableFocusListener
      >
        {children}
      </MuiTooltip>
    )
  } else {
    return children
  }
}

export default withStyles(styles)(Tooltip)
