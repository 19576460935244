import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { setUserData } from 'Actions/auth'
import { setTheme } from 'Actions/theme'
import { globalError } from './shell'

export function* getUserSaga() {
  const { ok, error, data } = yield http.get('/users/profile')
  if (ok) {
    yield put(setUserData(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* putUserSaga(action) {
  const {
    payload: { values, resolve, reject }
  } = action
  const { ok, error, data } = yield http.put('/users/profile', values)
  if (ok) {
    yield put(setUserData(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* putUserThemeSaga(action) {
  const { theme } = action
  window.localStorage.setItem('palette', theme)
  yield put(setTheme(theme))
}
