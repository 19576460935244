import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IntlProvider as Provider, createIntl } from 'react-intl'
import messagesEn from '../translations/en.json'
import messagesNl from '../translations/nl.json'
import {
  translate as utilsTranslate,
  getEntity as utilsGetEntity,
} from 'Utils/translate'
import { entity as entityObj } from 'Utils/constants'

const messages = {
  nl: messagesNl,
  en: messagesEn,
}

export let intl
export const t = (id, values) => utilsTranslate(intl)(id, values)

export const getEntity = (entity, plural) => {
  try {
    if (plural) {
      return entityObj.plural[entity]
    }
    return entityObj.single[entity]
  } catch (e) {
    return undefined
  }
}

export const getEntityCaption = (entity, plural = false) =>
  utilsGetEntity(intl, entity, plural)

const IntlProvider = ({ children, lang }) => {
  useEffect(() => {
    const intlContext = createIntl({
      locale: lang,
      messages: messages[lang],
    })

    intl = intlContext
  }, [lang])
  return (
    <Provider locale={lang} messages={messages[lang]}>
      {children}
    </Provider>
  )
}

export default connect(
  (state) => ({
    lang: state.app.language,
  }),
  null
)(IntlProvider)
