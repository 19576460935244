import React, { Component } from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'
import complexity from 'complexity'
import { FormattedMessage } from 'react-intl'
import is from 'is_js'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from 'Components/common/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'

const styles = () => ({
  barColor: {
    backgroundColor: red[100]
  },
  formControl: {
    width: '100%'
  },
  progress: {
    height: 2,
    marginTop: -10,
    width: '100%'
  },
  root: {
    height: 10
  },
  passwordInvalid: {
    backgroundColor: red[500]
  },
  passwordValid: {
    backgroundColor: green[500]
  },
  tooltip: {
    fontSize: 15
  },
  tooltipList: {
    paddingLeft: 0
  }
})

export const passwordOptions = {
  uppercase: 1,
  digit: 1,
  lowercase: 1,
  min: 8
}

export class TextField extends Component {
  state = {
    showPassword: false,
    value: 0
  }

  handleChange = event => {
    if (this.props.passwordStrength && this.props.meta.active) {
      const value = event.target.value
      const check = complexity.checkError(value, passwordOptions)
      this.setState(() => ({
        value:
          (check.min ? 25 : 0) +
          (check.digit ? 25 : 0) +
          (check.uppercase ? 25 : 0) +
          (check.lowercase ? 25 : 0)
      }))
    }
    this.props.input.onChange(event)
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClickShowPasssword = () => {
    this.setState(() => ({ showPassword: !this.state.showPassword }))
  }

  render = () => {
    const {
      classes,
      input: { ...inputProps },
      fullWidth = true,
      label,
      meta: { touched, error, active },
      password,
      passwordPreview = true,
      passwordStrength,
      required,
      ...other
    } = this.props
    other.autoFocus = is.mobile() || is.tablet() ? false : other.autoFocus
    return (
      <FormControl
        className={classNames(fullWidth && classes.formControl)}
        error={touched && !!error}
        required={required}
      >
        <InputLabel htmlFor={inputProps.name}>{label}</InputLabel>
        <Input
          {...inputProps}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          {...other}
          type={
            password
              ? this.state.showPassword
                ? 'text'
                : 'password'
              : other.type || 'text'
          }
          endAdornment={
            password &&
            passwordPreview && (
              <InputAdornment position="end">
                <IconButton
                  onClick={this.handleClickShowPasssword}
                  onMouseDown={this.handleMouseDownPassword}
                  tabIndex="-1"
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }
          onChange={this.handleChange}
          margin="dense"
        />
        <FormHelperText>
          {!(passwordStrength && active) && touched && error}
        </FormHelperText>
        {passwordStrength && active && (
          <Tooltip
            title={
              <div className={classes.tooltip}>
                <FormattedMessage id="common.form.textField.passwordStrength.title" />
                <ul className={classes.tooltipList}>
                  <li>
                    <FormattedMessage id="common.form.textField.passwordStrength.capital" />
                  </li>
                  <li>
                    <FormattedMessage id="common.form.textField.passwordStrength.digit" />
                  </li>
                  <li>
                    <FormattedMessage id="common.form.textField.passwordStrength.lowercase" />
                  </li>
                </ul>
              </div>
            }
            placement="bottom-start"
          >
            <LinearProgress
              variant="determinate"
              value={this.state.value}
              classes={{
                root: classes.root,
                barColorPrimary:
                  this.state.value < 100
                    ? classes.passwordInvalid
                    : classes.passwordValid,
                colorPrimary: classes.barColor
              }}
              className={classes.progress}
            />
          </Tooltip>
        )}
      </FormControl>
    )
  }
}

export default compose(withStyles(styles))(TextField)
