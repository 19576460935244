import React, { Component } from 'react'
import clsx from 'clsx'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Field, Form, reduxForm, submit } from 'redux-form'
import { connectModal } from 'redux-modal'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import RefreshIcon from '@material-ui/icons/Refresh'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from 'Root/app/withMobileDialog'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import { TextField, SelectField } from 'Components/common/form/'
import { add } from 'Actions/workOrder'

import withTranslate from 'Root/app/withTranslate'

const styles = (theme) => ({
  paper: {
    minWidth: 400,
  },
  rightButton: {
    marginLeft: theme.spacing(1),
  },
})

const validate = ({ dossier, language }, { t }) => {
  console.log(dossier, language)
  const errors = {}
  if (!language) {
    errors.language = t('shell.addDossier.error.language')
  }
  if (!dossier || dossier.length < 6) {
    errors.dossier = t('shell.addDossier.error.dossier')
  }
  return errors
}

class AddDossier extends Component {
  add = (values) =>
    new Promise((resolve, reject) => {
      this.props.add({
        values,
        resolve,
        reject,
      })
    })

  handleSubmit = async (values) => {
    const { handleHide } = this.props
    await this.add(values)
    handleHide()
  }

  handleSubmitClick = () => this.props.submit('addDossier')

  handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      this.props.handleHide()
    }
  }

  handleDossierInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
  }

  render() {
    const {
      classes,
      fullScreen,
      handleHide,
      handleSubmit,
      invalid,
      languages,
      pristine,
      show,
      submitting,
      t,
    } = this.props
    return (
      <Dialog
        classes={{
          paper: !fullScreen && classes.paper,
        }}
        fullScreen={fullScreen}
        open={show}
        onClose={this.handleClose}
      >
        <DialogTitle>
          <FormattedMessage id="shell.addDossier.title" />
        </DialogTitle>
        <DialogContent>
          <Form
            noValidate
            onSubmit={handleSubmit(this.handleSubmit)}
            autoComplete="off"
          >
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="dossier"
                  component={TextField}
                  placeholder={t('shell.addDossier.dossier.info')}
                  label={t('shell.addDossier.dossier.label')}
                  spellCheck={false}
                  onInput={this.handleDossierInput}
                  inputProps={{ maxLength: 7 }}
                  autoFocus
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="language"
                  component={SelectField}
                  label={t('shell.addDossier.language.label')}
                  items={languages}
                />
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHide} color="default" disabled={submitting}>
            <FormattedMessage id="shell.addDossier.button.cancel" />
          </Button>
          <Button
            onClick={this.handleSubmitClick}
            variant="contained"
            color="primary"
            disabled={pristine || invalid || submitting}
          >
            <FormattedMessage id="shell.addDossier.button.submit" />
            {submitting && (
              <RefreshIcon className={clsx('wn-spin', classes.rightButton)} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default compose(
  withTranslate,
  withStyles(styles),
  withMobileDialog(),
  connectModal({ name: 'addDossier' }),
  connect(
    (state) => ({
      languages: state.cache.lookups.language,
    }),
    { add, submit }
  ),
  reduxForm({
    enableReinitialize: true,
    form: 'addDossier',
    validate,
  })
)(AddDossier)
