import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let MonitorScreenshot = props => (
  <SvgIconCustom {...props}>
    <path d="M9 6H5v4h2V8h2m10 2h-2v2h-2v2h4m2 2H3V4h18m0-2H3c-1.11 0-2 .89-2 2v12a2 2 0 002 2h7v2H8v2h8v-2h-2v-2h7a2 2 0 002-2V4a2 2 0 00-2-2" />
  </SvgIconCustom>
)

MonitorScreenshot = pure(MonitorScreenshot)
MonitorScreenshot.muiName = 'SvgIcon'

export default MonitorScreenshot
