import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import deepmerge from 'deepmerge'

const themeReducer = createReducer(initialState.theme, {
  [types.THEME_SET_THEME]: (state, action) => {
    const palette = deepmerge({}, state.palette)
    return {
      ...state,
      palette: {
        ...palette,
        type: action.theme
      }
    }
  }
})

export default themeReducer
