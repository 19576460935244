import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let AccountClockOutline = props => (
  <SvgIconCustom {...props}>
    <path d="M16 14h1.5v2.82l2.44 1.41-.75 1.3L16 17.69V14m1-2a5 5 0 00-5 5 5 5 0 005 5 5 5 0 005-5 5 5 0 00-5-5m0-2a7 7 0 017 7 7 7 0 01-7 7c-2.79 0-5.2-1.64-6.33-4H1v-3c0-2.66 5.33-4 8-4 .6 0 1.34.07 2.12.2A6.992 6.992 0 0117 10m-7 7c0-.7.1-1.38.29-2-.42-.07-.86-.1-1.29-.1-2.97 0-6.1 1.46-6.1 2.1v1.1h7.19A6.71 6.71 0 0110 17M9 4a4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4 4 4 0 014-4m0 1.9A2.1 2.1 0 006.9 8 2.1 2.1 0 009 10.1 2.1 2.1 0 0011.1 8 2.1 2.1 0 009 5.9z" />
  </SvgIconCustom>
)

AccountClockOutline = pure(AccountClockOutline)
AccountClockOutline.muiName = 'SvgIcon'

export default AccountClockOutline
