import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const inspecionsReducer = createReducer(initialState.vehicle.inspections, {
  [types.VEHICLE_GET_INSPECTIONS_SUCCEED]: (state, action) => ({
    ...state,
    list: action.data
  })
})

export default combineReducers({
  inspections: inspecionsReducer
})
