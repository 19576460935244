import { call } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'

export function* addSaga(action) {
  const { payload } = action
  const { resolve, values } = payload
  const { ok, error, data } = yield http.post('/work-orders', values)
  if (ok) {
    return yield call(resolve, true)
  } else if (error) {
    yield call(globalError, data)
  }
  yield call(resolve, false)
}
