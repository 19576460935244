export default (bytes, decimals) => {
  if (typeof bytes === 'undefined') {
    return ''
  }
  if (bytes === 0) {
    return '0 Bytes'
  }
  const k = 1024
  const dm = decimals || 2
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
