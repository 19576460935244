import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let CarBrakeAlert = props => (
  <SvgIconCustom {...props}>
    <path d="M11 15h2v2h-2v-2m0-8h2v6h-2V7m1-4a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9m0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7m8.5 1.5c2.16-2.19 3.5-5.19 3.5-8.5 0-3.31-1.34-6.31-3.5-8.5l-1.08 1.08C21.32 6.5 22.5 9.11 22.5 12c0 2.9-1.18 5.5-3.08 7.42l1.08 1.08M4.58 19.42C2.68 17.5 1.5 14.9 1.5 12c0-2.89 1.18-5.5 3.08-7.42L3.5 3.5C1.34 5.69 0 8.69 0 12c0 3.31 1.34 6.31 3.5 8.5l1.08-1.08z" />
  </SvgIconCustom>
)

CarBrakeAlert = pure(CarBrakeAlert)
CarBrakeAlert.muiName = 'SvgIcon'

export default CarBrakeAlert
