import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let FormatListChecks = props => (
  <SvgIconCustom {...props}>
    <path d="M3 5h6v6H3V5m2 2v2h2V7H5m6 0h10v2H11V7m0 8h10v2H11v-2m-6 5l-3.5-3.5 1.41-1.41L5 17.17l4.59-4.58L11 14l-6 6z" />
  </SvgIconCustom>
)

FormatListChecks = pure(FormatListChecks)
FormatListChecks.muiName = 'SvgIcon'

export default FormatListChecks
