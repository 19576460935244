import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import compose from 'recompose/compose'
import classNames from 'classnames'
import { reduxForm, Field, Form, formValueSelector } from 'redux-form'
import is from 'is_js'
import queryString from 'query-string'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Base from 'Components/shell/PublicBase'
import Tooltip from 'Components/common/Tooltip'
import TextField from 'Components/common/form/TextField'
import { signin } from 'Actions/auth'
import withTranslate from 'Root/app/withTranslate'

const styles = () => ({
  divider: {
    marginBottom: 10,
    marginTop: 10
  },
  hidden: {
    display: 'none'
  },
  marginBottom: {
    marginBottom: 30
  },
  submit: {
    marginTop: 20
  },
  twoFactorEmail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const validate = ({ email, password }, { t }) => {
  const errors = {}
  if (!email) {
    errors.email = t('auth.signin.error.email.required')
  }
  if (!password) {
    errors.password = t('auth.signin.error.password.required')
  }
  if (email && is.not.email(email)) {
    errors.email = t('auth.signin.error.email.invalid')
  }
  return errors
}

export class Signin extends Component {
  state = {
    twoFactor: false
  }

  signin = values =>
    new Promise((resolve, reject) => {
      this.props.signin({
        values,
        resolve,
        reject
      })
    })

  handleSubmit = async values => {
    const redirect = queryString.parse(this.props.location.search).redirect
    const data = { ...values, redirect }
    try {
      const twoFactor = await this.signin(data)
      if (twoFactor) {
        this.setState({ twoFactor: true })
      }
    } catch (e) {}
  }

  handleInputCode = e => {
    e.target.value = e.target.value.toString().slice(0, 6)
  }

  handleGotoLoginClick = () => {
    this.props.change('password', '')
    this.setState({ twoFactor: false })
  }

  render() {
    const {
      classes,
      email,
      hasEmail,
      handleSubmit,
      invalid,
      pristine,
      t,
      submitting
    } = this.props
    const { twoFactor } = this.state
    return (
      <Base title={t('auth.signin.title')}>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          autoComplete="off"
          noValidate
        >
          <GridContainer>
            <GridItem
              className={classNames(twoFactor && classes.hidden)}
              xs={12}
            >
              <Field
                name="email"
                required
                type="email"
                component={TextField}
                placeholder={t('auth.signin.email.placeholder')}
                label={t('auth.signin.email.label')}
                autoFocus={!hasEmail}
              />
              <Field
                password
                required
                name="password"
                component={TextField}
                placeholder={t('auth.signin.password.placeholder')}
                label={t('auth.signin.password.label')}
                autoFocus={hasEmail}
              />
            </GridItem>
            <GridItem
              className={classNames(!twoFactor && classes.hidden)}
              xs={12}
            >
              <Typography variant="h6">
                <FormattedMessage id="auth.signin.twoFactor.title" />
              </Typography>
              <Typography>
                <FormattedMessage id="auth.signin.twoFactor.subtitle1" />
              </Typography>
              <div className={classes.twoFactorEmail}>
                <Typography noWrap>{email}</Typography>
                <Tooltip title={t('auth.signin.twoFactor.email.tooltip')}>
                  <IconButton onClick={this.handleGotoLoginClick}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </GridItem>
            <GridItem
              className={classNames(!twoFactor && classes.hidden)}
              xs={12}
            >
              <Typography>
                <FormattedHTMLMessage id="auth.signin.twoFactor.subtitle2" />
              </Typography>
            </GridItem>
            {twoFactor && (
              <GridItem
                className={classNames(!twoFactor && classes.hidden)}
                xs={12}
              >
                <Field
                  type="number"
                  onInput={this.handleInputCode}
                  name="token"
                  component={TextField}
                  label={t('auth.signin.code.label')}
                  autoFocus
                  fullWidth={false}
                />
              </GridItem>
            )}
            <GridItem xs={12} className={classes.marginBottom}>
              <GridContainer justify="center">
                <Button
                  variant="contained"
                  className={classes.submit}
                  type="submit"
                  color="primary"
                  disabled={pristine || invalid || submitting}
                >
                  <FormattedMessage id="auth.signin.button.submit" />
                </Button>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Form>
      </Base>
    )
  }
}

const selector = formValueSelector('signin')

export default compose(
  withTranslate,
  connect(
    state => ({
      email: selector(state, 'email'),
      hasEmail: !!state.auth.user.email,
      initialValues: {
        email: state.auth.user.email
      }
    }),
    { signin }
  ),
  withStyles(styles),
  reduxForm({
    enableReinitialize: true,
    form: 'signin',
    shouldError: () => true,
    validate
  })
)(Signin)
