import React from 'react'
import { DateTimePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import withTranslate from 'Root/app/withTranslate'

const DateTimeField = ({
  input,
  label,
  meta: { touched, error, active },
  t,
  getEntityCaption,
  ...other
}) => {
  const { value, onChange, name } = input
  return (
    <FormControl error={touched && !!error}>
      <DateTimePicker
        name={name}
        label={label}
        value={value || null}
        onChange={onChange}
        ampm={false}
        cancelLabel={t('common.form.dateTimeField.cancelLabel')}
        clearLabel={t('common.form.dateTimeField.clearLabel')}
        format="DD-MM-YYYY HH:mm"
        {...other}
      />
      <FormHelperText>{!active && touched && error}</FormHelperText>
    </FormControl>
  )
}

export default withTranslate(DateTimeField)
