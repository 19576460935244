import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import classNames from 'classnames'
import { connectModal } from 'redux-modal'
import { withStyles } from '@material-ui/core/styles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import RefreshIcon from '@material-ui/icons/Refresh'
import withTranslate from 'Root/app/withTranslate'
import { confirmNo, confirmYes } from 'Actions/shell'

const styles = theme => ({
  content: {
    whiteSpace: 'pre-line'
  },
  rightButton: {
    marginLeft: theme.spacing(1)
  }
})

class Confirm extends Component {
  state = {
    delayed: false
  }

  static propTypes = {
    cancelCaption: PropTypes.string,
    cancelVisible: PropTypes.bool,
    content: PropTypes.string,
    noCaption: PropTypes.string,
    noVisible: PropTypes.bool,
    title: PropTypes.string,
    yesCaption: PropTypes.string
  }
  static defaultProps = {
    noVisible: true
  }

  handleCancelClick = () => {
    this.props.confirmNo()
    this.props.handleHide()
  }
  handleNoClick = () => {
    this.props.confirmNo()
    this.props.handleHide()
  }
  handleYesClick = () => {
    this.props.confirmYes()
    const { delay, done, handleHide } = this.props
    if (delay || done) {
      this.setState(
        () => ({ delayed: true }),
        () => {
          if (delay) {
            setTimeout(() => handleHide(), delay)
          } else {
            done && handleHide()
          }
        }
      )
    } else {
      this.props.handleHide()
    }
  }
  handleKeyPress = e => {
    e.key === 'Enter' && this.handleYesClick()
  }
  render = () => {
    const { delayed } = this.state
    const {
      classes,
      fullScreen,
      show,
      handleHide,
      cancelCaption,
      cancelVisible,
      content,
      noCaption,
      noVisible,
      t,
      title,
      yesCaption
    } = this.props
    return (
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={handleHide}
        onKeyPress={this.handleKeyPress}
        disableBackdropClick
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <div
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancelVisible && (
            <Button onClick={this.handleCancelClick} color="default">
              {cancelCaption || t('shell.Confirm.cancelCaption')}
            </Button>
          )}
          {noVisible && (
            <Button
              variant={cancelVisible ? 'raised' : 'text'}
              onClick={this.handleNoClick}
              color="default"
              disabled={delayed}
            >
              {noCaption || t('shell.Confirm.noCaption')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleYesClick}
            disabled={delayed}
          >
            {yesCaption || t('shell.Confirm.yesCaption')}
            {delayed && (
              <RefreshIcon
                className={classNames('wn-spin', classes.rightButton)}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default compose(
  withTranslate,
  connect(
    null,
    { confirmNo, confirmYes }
  ),
  withStyles(styles),
  withMobileDialog(),
  connectModal({ name: 'confirm' })
)(Confirm)
