import React, { Component } from 'react'
import FileDrop from 'react-file-drop'
import classNames from 'classnames'

import uniqueId from 'lodash.uniqueid'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import formatBytes from 'Utils/formatBytes'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ClearIcon from '@material-ui/icons/Clear'
import Tooltip from 'Components/common/Tooltip'

const styles = theme => {
  return {
    background: {
      backgroundColor: theme.palette.divider
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 10,
      paddingTop: 10
    },
    docIcon: {
      marginRight: 10,
      fill:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.grey[400]
    },
    input: {
      display: 'none'
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    name: {
      flex: '1 1 auto'
    },
    rightIcon: {}
  }
}

class Upload extends Component {
  state = {
    name: '',
    type: '',
    size: undefined,
    uploadId: uniqueId('upload_')
  }
  static getDerivedStateFromProps(props) {
    if (props.listValue) {
      console.log(props.listValue)
      return {
        name: props.listValue.name,
        size: props.listValue.size
      }
    }
    return null
  }

  handleInputChange = e => {
    const file = e.target.files[0]
    this.uploadFile(file)
  }

  handleClearClick = () => {
    const values = { name: '', size: undefined, type: '' }
    this.setState(() => ({ ...values }))
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(values)
    }
    if (this.props.onFileLoad) {
      this.props.onFileLoad({ target: { value: '' } }, {})
    }
  }

  uploadFile = file => {
    const reader = new FileReader()
    reader.onload = e => {
      const data = e.target.result
      const values = { name: file.name, size: file.size, type: file.type }
      this.setState({ ...values })
      if (this.props.input && this.props.input.onChange) {
        this.props.input.onChange({ ...values, data })
      }
      if (this.props.onFileLoad) {
        this.props.onFileLoad(e, file, data)
      }
    }
    reader.readAsDataURL(file)
  }

  handleDrop = files => {
    const file = files[0]
    this.uploadFile(file)
  }

  render() {
    const {
      classes,
      children,
      docIcon,
      showName = true,
      text,
      title,
      uploadIcon
    } = this.props
    const { name, size, uploadId } = this.state
    const newUploadIcon =
      uploadIcon ||
      React.cloneElement(<CloudUploadIcon />, {
        className: classNames((children || text) && classes.leftIcon)
      })
    const newDocIcon = docIcon
      ? React.cloneElement(docIcon, {
          className: classNames(classes.leftIcon, classes.docIcon)
        })
      : null
    return (
      <FileDrop onDrop={this.handleDrop}>
        <div
          className={classNames(classes.container, name && classes.background)}
        >
          {name && !!newDocIcon && <div>{newDocIcon}</div>}
          {!name && (
            <div>
              <input
                key={uploadId}
                className={classes.input}
                id={uploadId}
                type="file"
                onChange={this.handleInputChange}
              />
              <label htmlFor={uploadId}>
                <Tooltip title={title}>
                  <Button color="primary" component="span">
                    {newUploadIcon}
                    {children || text}
                  </Button>
                </Tooltip>
              </label>
            </div>
          )}
          {showName && (
            <div className={classes.name}>
              <Typography variant="body1">{name}</Typography>
              <Typography variant="body1">
                <em>{formatBytes(size)}</em>
              </Typography>
            </div>
          )}
          {!!name && showName && (
            <div>
              <IconButton
                className={classNames(classes.rightIcon)}
                onClick={this.handleClearClick}
              >
                <ClearIcon />
              </IconButton>
            </div>
          )}
        </div>
      </FileDrop>
    )
  }
}

export default withStyles(styles)(Upload)
