import { call, put, take, race } from 'redux-saga/effects'
import { show } from 'redux-modal'
import { showMessage } from 'Actions/shell'
import * as types from 'Actions/types'
import { t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'

export function* confirmSaga(action) {
  const { title, content, options, resolve } = action
  yield put(show('confirm', { title, content, ...options }))
  const { yes } = yield race({
    yes: take(types.SHELL_CONFIRM_YES),
    no: take(types.SHELL_CONFIRM_NO)
  })
  if (resolve) {
    yield call(resolve, !!yes)
  } else {
    return !!yes
  }
}

export function* messageSaga(action) {
  const { title, content } = action
  yield put(
    show('confirm', { title, content, noVisible: false, yesCaption: 'OK' })
  )
  yield race({
    yes: take(types.SHELL_CONFIRM_YES)
  })
}

export function* globalError(data, reject) {
  if (reject) {
    yield call(reject)
  }
  yield put(showMessage(t('global.error.unknown'), messageLevel.error))
  if (data) {
    console.error(data.error)
  }
}
