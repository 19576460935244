import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import { addLookups } from 'Actions/cache'

export function* loadLookupsSaga() {
  const { ok, data } = yield http.get(`/lookups`)
  if (ok) {
    yield put(addLookups(data))
  } else {
    yield call(globalError, data)
  }
}
