import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import {
  getCompanies,
  initSessionSucceed,
  setCompanies,
  setCompanyData,
  setQrCode,
  setTwoFactorEnabled,
  signinSucceed,
  signoutSucceed,
} from 'Actions/auth'
import { showMessage } from 'Actions/shell'
import { gotoUrl } from 'Actions/app'
import { globalError } from './shell'
import { t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'
import history from 'Utils/history'
import { confirmSaga } from './shell'

export function* changePasswordSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, data, badRequest } = yield http.post(
    '/auth/change-password',
    values
  )
  if (ok) {
    yield put(
      showMessage(t('auth.changePassword.info.succeed'), messageLevel.info)
    )
    yield call(resolve)
  } else if (badRequest) {
    yield put(
      showMessage(
        t('auth.changePassword.error.oldPassword.invalid'),
        messageLevel.warning
      )
    )
  } else {
    yield call(globalError, data, reject)
  }
}

export function* checkPasswordSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, data } = yield http.post('/auth/check-password', values)
  if (ok) {
    yield call(resolve)
  } else if (data.error == 'Unauthorized') {
    yield put(
      showMessage(
        t('auth.twoFactor.error.password.invalid'),
        messageLevel.warning
      )
    )
    yield call(reject)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* getCompaniesSaga() {
  const { ok, error, data } = yield http.get('/companies')
  if (ok) {
    yield put(setCompanies(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getCompanySaga() {
  const { ok, error, data } = yield http.get('/companies/my')
  if (ok) {
    yield put(setCompanyData(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getQrCodeSaga(action) {
  const { password } = action
  const { ok, data } = yield http.post('/auth/twofactor-qrcode', { password })
  if (ok) {
    yield put(setQrCode(data))
  } else {
    yield call(globalError, data)
  }
}

export function* initSessionSaga() {
  const { ok, error, data } = yield http.get('/auth/info')
  if (ok) {
    yield put(initSessionSucceed(data))
    yield put(getCompanies())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* putCompanySaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, error, data } = yield http.put('/companies/my', values)
  if (ok) {
    yield put(setCompanyData(data))
    yield call(resolve)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

function* signinOkSaga(action) {
  const {
    email,
    token,
    redirect,
    role = 'user',
    subscription,
    subscriptionEnded,
    initialized = true,
  } = action
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('email', email)
  yield put(
    signinSucceed({
      user: { email, role },
      info: { subscription, subscriptionEnded, initialized },
    })
  )

  const location = redirect || '/'
  yield put(gotoUrl(location))
}

export function* signinSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, badRequest, unauthorized, data } = yield http.post(
    '/auth/signin',
    values
  )
  if (ok) {
    yield call(resolve)
    yield call(signinOkSaga, {
      ...data,
      redirect: values.redirect,
    })
  } else if (unauthorized) {
    yield put(
      showMessage(t('auth.signin.error.signin.invalid'), messageLevel.warning)
    )
    yield call(reject)
  } else if (badRequest) {
    if (data.error === 'Code not valid') {
      yield put(
        showMessage(t('auth.signin.error.code.invalid'), messageLevel.warning)
      )
      yield call(reject)
    } else if (data.error === '2fa code missing') {
      yield call(resolve, true)
    }
  } else {
    yield call(globalError, data, reject)
  }
}

export function* signoutSaga(action) {
  const { confirm } = action
  if (confirm) {
    const ok = yield call(confirmSaga, {
      title: t('auth.message.signout.title'),
      content: t('auth.message.signout.content'),
    })
    if (!ok) {
      return
    }
  }
  yield http.post('/auth/signout')
  window.localStorage.removeItem('token')
  yield put(signoutSucceed())
  yield put(gotoUrl('/'))
}

export function* switchCompany(action) {
  const { id } = action
  const { ok, data } = yield http.post(`/auth/switch-company/${id}`)
  if (ok) {
    const redirect = history.location.pathname + history.location.search
    window.localStorage.removeItem('token')
    yield put(signoutSucceed())
    yield call(signinOkSaga, {
      ...data,
      redirect,
    })
  } else {
    yield call(globalError, data)
  }
}

export function* twoFactorEnableSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { ok, data } = yield http.post('/auth/twofactor', values)
  if (ok) {
    yield put(setTwoFactorEnabled(values.enabled))
    yield call(resolve)
  } else if (data.error === 'Invalid code') {
    yield put(showMessage(t('auth.twoFactor.error.code.invalid'), 'error'))
    yield call(reject)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* validateTokenSaga() {
  const token = window.localStorage.getItem('token')
  if (token) {
    const { data } = yield http.get(`/auth/tokenvalid/${token}`)
    if (data && data.valid) {
      return yield put(
        signinSucceed({
          user: {
            email: data.email,
            role: data.role,
          },
          info: {
            subscription: data.subscription,
            subscriptionEnded: data.subscriptionEnded,
            initialized: data.initialized,
          },
        })
      )
    }
  }
  yield put(signoutSucceed())
}
