import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import GridContainer from 'Components/common/GridContainer'
import GridItem from 'Components/common/GridItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RepairRoomIcon from 'Icons/RepairRoom'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from 'Components/common/Tooltip'
import { toArray } from 'Utils/lookup'
import * as Icons from 'Icons'
import withTranslate from 'Root/app/withTranslate'
import { changeLanguage } from 'Actions/app'

const styles = theme => ({
  children: {
    marginTop: 30
  },
  repairRoomIcon: {
    fontSize: 72,
    marginLeft: -5,
    marginTop: -10
  },
  repairText: {
    color: '#588aba',
    fontWeight: 500
  },
  roomText: {
    color: '#646567',
    fontWeight: 500
  },
  languages: {
    marginTop: 20
  },
  paper: {
    padding: 40,
    paddingTop: 20,
    paddingBottom: 20,
    width: 340,
    maxWidth: 340
  },
  root: {
    flexGrow: 1,
    marginTop: 30,
    padding: 8
  },
  titleMain: {
    textAlign: 'right',
    textTransform: 'uppercase',
    color:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.light
  },
  titleSub: {
    color: theme.palette.text.secondary,
    textAlign: 'right'
  }
})

export class PublicBase extends Component {
  handleLanguageClick = language => () => {
    const { changeLanguage } = this.props
    changeLanguage(language)
  }
  render = () => {
    const { children, classes, languages, t, title } = this.props
    return (
      <div className={classes.root}>
        <GridContainer justify="center">
          <Paper className={classes.paper} elevation={2}>
            <GridContainer direction="row">
              <GridItem xs={2}>
                <RepairRoomIcon className={classes.repairRoomIcon} />
              </GridItem>
              <GridItem xs={10}>
                <GridContainer direction="column" alignItems="flex-end">
                  <GridItem xs={12}>
                    <Typography variant="h5" className={classes.titleMain}>
                      <span className={classes.repairText}>Repair</span>
                      <span className={classes.roomText}>Room</span>
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.titleSub}
                    >
                      {title}
                    </Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridItem className={classes.children} xs={12}>
              {children}
            </GridItem>
            <GridContainer justify="center" className={classes.languages}>
              <GridItem>
                {toArray(languages).map(language => {
                  const Icon = language && language.icon && Icons[language.icon]
                  return (
                    <Tooltip
                      key={language.id}
                      title={t(`lookup.language.${language.id}`)}
                    >
                      <IconButton
                        onClick={this.handleLanguageClick(language.id)}
                      >
                        {Icon && <Icon />}
                      </IconButton>
                    </Tooltip>
                  )
                })}
              </GridItem>
            </GridContainer>
          </Paper>
        </GridContainer>
      </div>
    )
  }
}

export default compose(
  withTranslate,
  withStyles(styles),
  connect(
    state => ({ languages: state.app.languages || {} }),
    { changeLanguage }
  )
)(PublicBase)
