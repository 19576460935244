import { createSelector } from 'reselect'
import { toArray } from 'Utils/lookup'
import { role } from 'Utils/constants'

const getRole = state => state.auth.user.role
const getLookup = (state, props) => state.cache.lookups[props.list]
const getLookupDef = (state, props) =>
  state.cache.lookups['lookupList']
    ? state.cache.lookups['lookupList'][props.list]
    : null

export const getListItems = createSelector(
  [getLookup],
  items => {
    return toArray(items)
  }
)

export const hasDeletedItems = createSelector(
  [getLookup],
  items => toArray(items).some(x => x.mutation.deleted)
)

export const getTitle = createSelector(
  [getLookupDef],
  lookup => {
    if (lookup) {
      return lookup.name
    }
    return 'Unkown'
  }
)

export const canManage = createSelector(
  [getLookupDef, getRole],
  (lookup, userRole) => {
    if (userRole !== role.admin) {
      return false
    }
    if (lookup) {
      return !lookup.system
    }
    return false
  }
)

export const getDefaultIcon = createSelector(
  [getLookupDef],
  lookup => (lookup ? lookup.icon : '')
)
