import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { show } from 'redux-modal'
import { compose } from 'recompose'
import { withStyles, withTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
// import MainBar from './MainBar'
// import MainDrawer from './MainDrawer'
// import RootRouter from 'Routes/RootRouter'
import Confirm from 'Components/shell/Confirm'
import AddDossier from 'Components/shell/AddDossier'
import { setEditMode } from 'Actions/entity'
import { load, reset } from 'Actions/table'
import { hideMessage } from 'Actions/shell'
import { initSession, switchCompany } from 'Actions/auth'
import { gotoUrl, dockNavbar, openNavbar } from 'Actions/app'

const MainBar = React.lazy(() => import('./MainBar'))
const MainDrawer = React.lazy(() => import('./MainDrawer'))
const RootRouter = React.lazy(() => import('Routes/RootRouter'))
const Clock = React.lazy(() => import('Components/attendance/Clock/'))

const drawerWidth = 260

const styles = (theme) => ({
  '@global': {
    body: {
      overflow: 'hidden',
    },
  },
  mainBar: {
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minHeight: 64,
    maxHeight: 64,
  },
  mainBarShift: {
    borderLeft: 0,
    left: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      left: drawerWidth,
      transition: theme.transitions.create(['left', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  frame: {
    left: 0,
    height: 'auto',
    position: 'absolute',
    width: 'auto',
    right: 0,
    bottom: 0,
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
  },
  frameContent: {
    borderLeftStyle: 'none !important',
    margin: 0,
    paddingTop: 0,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    left: 0,
    right: 0,
    position: 'absolute',
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  frameContentShift: {
    left: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      borderColor: theme.palette.divider,
      borderLeftStyle: 'solid',
      borderLeftWidth: 1,
      left: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['left', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
})

class Root extends Component {
  toggleDrawerOpen = (value = false) => {
    this.props.openNavbar(value)
    if (!value) {
      this.props.dockNavbar(false)
    }
  }
  toggleDrawerPersistent = (value = false) => {
    this.props.dockNavbar(value)
    window.localStorage.setItem('docked', JSON.stringify(value))
    this.props.openNavbar(value)
  }
  componentWillUnmount = () => {
    console.log('unmount')
  }

  componentDidMount = () => {
    this.props.initSession()
  }

  gotoUrl = (item) =>
    new Promise((resolve) => {
      this.props.gotoUrl(item, resolve)
    })

  handleItemClick = async (item, filter, options) => {
    if (filter && typeof filter === 'string') {
      if (filter && typeof filter === 'string') {
        await this.gotoUrl(`/${item}/?${filter}&${options}`)
      } else {
        await this.gotoUrl(`/${item}/?${filter}`)
      }
    } else {
      await this.gotoUrl(`/${item}`)
    }
    this.props.setEditMode(false)
  }

  handleCompanyChange = (id) => {
    this.props.switchCompany(id)
  }

  render = () => {
    console.log('render root')
    const {
      classes,
      companies,
      company,
      companyId,
      navbarDocked,
      navbarOpen,
      role,
      theme,
    } = this.props
    const isAttendance = role === 'attendance'
    return (
      <Suspense fallback={<div />}>
        {isAttendance ? (
          <div>
            <Clock />
            <Confirm />
          </div>
        ) : (
          <div>
            <div
              className={classNames(
                classes.mainBar,
                navbarDocked && classes.mainBarShift
              )}
            >
              <MainBar
                drawerOpen={navbarOpen}
                onToggleDrawerOpen={this.toggleDrawerOpen}
              />
            </div>
            <div className={classes.frame}>
              <div
                className={classNames(
                  classes.frameContent,
                  navbarDocked && classes.frameContentShift
                )}
              >
                <RootRouter />
              </div>
            </div>
            <MainDrawer
              companies={companies}
              company={company}
              companyId={companyId}
              drawerWidth={drawerWidth}
              drawerPersistent={navbarDocked}
              drawerOpen={navbarOpen}
              onCompanyChange={this.handleCompanyChange}
              onToggleDrawerOpen={this.toggleDrawerOpen}
              onToggleDrawerPersistent={this.toggleDrawerPersistent}
              onItemClick={this.handleItemClick}
              rootTheme={theme}
            />
            <Confirm />
            <AddDossier />
          </div>
        )}
      </Suspense>
    )
  }
}

export default compose(
  connect(
    (state) => ({
      companies: state.auth.companies,
      company: state.auth.company.name,
      companyId: state.auth.company.id,
      language: state.app.language,
      navbarDocked: state.app.navbarDocked,
      navbarOpen: state.app.navbarOpen,
      role: state.auth.user.role,
    }),
    {
      dockNavbar,
      gotoUrl,
      hideMessage,
      initSession,
      load,
      openNavbar,
      reset,
      setEditMode,
      show,
      switchCompany,
    }
  ),
  withStyles(styles),
  withTheme
)(Root)
