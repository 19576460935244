import { put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { setLanguages } from 'Actions/lookup'

export function* loadLanguagesSaga() {
  const { ok, data } = yield http.get(`/lookups/language`)
  if (ok) {
    yield put(setLanguages(data))
  }
}
