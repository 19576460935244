import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let PlayBack = props => (
  <SvgIconCustom {...props}>
    <path d="M16 19.14v-14l-11 7 11 7z" />
  </SvgIconCustom>
)

PlayBack = pure(PlayBack)
PlayBack.muiName = 'SvgIcon'

export default PlayBack
