import * as types from './types'
import { createAction } from 'Utils/redux'

export const changePassword = createAction(
  types.AUTH_CHANGE_PASSWORD,
  'payload'
)
export const checkPassword = createAction(types.AUTH_CHECK_PASSWORD, 'payload')
export const companyGet = createAction(types.AUTH_COMPANY_GET)
export const companyPut = createAction(types.AUTH_COMPANY_PUT, 'payload')
export const getCompanies = createAction(types.AUTH_GET_COMPANIES)
export const getQrCode = createAction(types.AUTH_GET_QRCODE, 'password')
export const initSession = createAction(types.AUTH_SESSION_INIT)
export const initSessionSucceed = createAction(types.AUTH_SESSION, 'data')
export const setCompanyData = createAction(types.AUTH_COMPANY_SET_DATA, 'data')
export const setCompanies = createAction(types.AUTH_SET_COMPANIES, 'data')
export const setQrCode = createAction(types.AUTH_SET_QRCODE, 'data')
export const setTwoFactorEnabled = createAction(
  types.AUTH_SET_TWOFACTOR_ENABLED,
  'enabled'
)
export const setUserData = createAction(types.AUTH_USER_SET_DATA, 'data')
export const signin = createAction(types.AUTH_SIGNIN, 'payload')
export const signinSucceed = createAction(types.AUTH_SIGNIN_SUCCEED, 'data')
export const signout = createAction(types.AUTH_SIGNOUT, 'confirm')
export const signoutSucceed = createAction(types.AUTH_SIGNOUT_SUCCEED)
export const switchCompany = createAction(types.AUTH_SWITCH_COMPANY, 'id')
export const twoFactorEnable = createAction(
  types.AUTH_TWOFACTOR_ENABLE,
  'payload'
)
export const userGet = createAction(types.AUTH_USER_GET)
export const userPut = createAction(types.AUTH_USER_PUT, 'payload')
export const userPutTheme = createAction(types.AUTH_USER_PUT_THEME, 'theme')
export const validateToken = createAction(types.AUTH_VALIDATE_TOKEN)
