import * as types from './types'
import { createAction } from 'Utils/redux'

// level: error, warning
export const showMessage = createAction(
  types.SHELL_MESSAGE_SHOW,
  'text',
  'level',
  'duration'
)
export const hideMessage = createAction(types.SHELL_MESSAGE_HIDE)

export const confirm = createAction(
  types.SHELL_CONFIRM,
  'title',
  'content',
  'options',
  'resolve'
)
export const confirmNo = createAction(types.SHELL_CONFIRM_NO)
export const confirmYes = createAction(types.SHELL_CONFIRM_YES)
