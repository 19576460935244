import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let CheckboxMarkedOutline = props => (
  <SvgIconCustom {...props}>
    <path d="M19 19H5V5h10V3H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-8h-2m-11.09-.92L6.5 11.5 11 16 21 6l-1.41-1.42L11 13.17l-3.09-3.09z" />
  </SvgIconCustom>
)

CheckboxMarkedOutline = pure(CheckboxMarkedOutline)
CheckboxMarkedOutline.muiName = 'SvgIcon'

export default CheckboxMarkedOutline
