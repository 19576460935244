import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const companiesReducer = createReducer(initialState.auth.companies, {
  [types.AUTH_SET_COMPANIES]: (state, { data }) => data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.companies
  })
})

const companyReducer = createReducer(initialState.auth.company, {
  [types.AUTH_COMPANY_SET_DATA]: (state, action) => ({
    ...state,
    ...action.data
  }),
  [types.AUTH_SESSION]: (state, action) => ({
    ...state,
    ...action.data.company
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.company
  })
})

const infoReducer = createReducer(initialState.auth.info, {
  [types.AUTH_SIGNIN_SUCCEED]: (state, action) => ({
    ...state,
    ...action.data.info,
    authenticated: true
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.info,
    authenticated: false
  })
})

const twoFactorReducer = createReducer(initialState.auth.twoFactor, {
  [types.AUTH_SET_QRCODE]: (state, action) => ({
    ...state,
    ...action.data
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.twofactor
  })
})

const userReducer = createReducer(initialState.auth.user, {
  [types.AUTH_SESSION]: (state, action) => ({
    ...state,
    ...action.data.user
  }),
  [types.AUTH_USER_SET_DATA]: (state, action) => ({
    ...state,
    ...action.data
  }),
  [types.AUTH_SIGNIN_SUCCEED]: (state, action) => ({
    ...state,
    ...action.data.user
  })
})

export default combineReducers({
  companies: companiesReducer,
  company: companyReducer,
  info: infoReducer,
  twoFactor: twoFactorReducer,
  user: userReducer
})
