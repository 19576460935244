import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = () => ({
  flexbox: {
    display: 'flex'
  },
  item: {
    paddingBottom: '0px !important',
    paddingTop: '0px !important'
  }
})

const GridItem = ({ children, classes, row, className, ...props }) => (
  <Grid
    item
    classes={{ item: classes.item }}
    className={classNames({ [classes.flexbox]: row }, className)}
    {...props}
  >
    {children}
  </Grid>
)

export default withStyles(styles)(GridItem)
