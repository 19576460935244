import * as types from 'Actions/types'
import { createAction } from 'Utils/redux'

export const get = createAction(types.ENTITY_GET, 'id')
export const put = createAction(types.ENTITY_PUT, 'id', 'values')
export const resetData = createAction(types.ENTITY_RESET_DATA)
export const setContentOverflowX = createAction(
  types.ENTITY_SET_CONTENTOVERFLOWX,
  'value'
)
export const setContentOverflowY = createAction(
  types.ENTITY_SET_CONTENTOVERFLOWY,
  'value'
)
export const setData = createAction(types.ENTITY_SET_DATA, 'data')
export const setEditMode = createAction(types.ENTITY_SET_EDITMODE, 'editMode')
export const setEditForm = createAction(types.ENTITY_SET_EDITFORM, 'editForm')
export const setId = createAction(types.ENTITY_SET_ID, 'id')
export const setLoading = createAction(types.ENTITY_SET_LOADING, 'loading')
export const setPrevLocation = createAction(
  types.ENTITY_SET_PREVLOCATION,
  'prevLocation'
)
export const setTabCaption = createAction(
  types.ENTITY_SET_TAB_CAPTION,
  'tabCaption'
)
export const setTabCount = createAction(types.ENTITY_SET_TAB_COUNT, 'tabCount')
export const setType = createAction(types.ENTITY_SET_TYPE, 'entityType')
export const updateData = createAction(types.ENTITY_UPDATE_DATA, 'data')
