import * as types from './types'
import { createAction } from 'Utils/redux'

export const checkToken = createAction(types.ATTENDANCE_CHECK_TOKEN, 'payload')
export const getAttendances = createAction(types.ATTENDANCE_GET)
export const getAttendancesSucceed = createAction(
  types.ATTENDANCE_GET_SUCCEED,
  'data'
)
export const register = createAction(types.ATTENDANCE_REGISTER, 'id', 'image')
export const startRefreshing = createAction(types.ATTENDANCE_START_REFRESHING)
