import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest-ie11-compatible'
import Badge from '@material-ui/core/Badge'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { withStyles } from '@material-ui/core/styles'

const renderInput = inputProps => {
  const {
    classes,
    autoFocus,
    value,
    ref,
    label,
    placeholder,
    inputClassName,
    name,
    ...other
  } = inputProps
  return (
    <FormControl classes={{ root: classNames(inputClassName) }}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <Input
        name={name}
        value={value}
        inputRef={ref}
        autoComplete="off"
        placeholder={placeholder}
        classes={{
          root: classes.input
        }}
        {...other}
      />
    </FormControl>
  )
}

const renderSuggestionsContainer = options => {
  const { containerProps, children } = options
  return (
    <Paper style={{ zIndex: 10 }} {...containerProps} square>
      {children}
    </Paper>
  )
}

const getSuggestionValue = suggestion => {
  return suggestion.name
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  containerMaxWidth: {
    maxWidth: 250
  },
  input: {
    height: 29
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestionNumber: {
    marginRight: 5
  },
  suggestionNumberColor: {
    backgroundColor: theme.palette.grey[400],
    color: 'white'
  },
  suggestionText: {
    width: '100%'
  },
  textField: {
    width: '100%'
  }
})

class Autocomplete extends PureComponent {
  state = {
    name: '',
    suggestions: []
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.props.onSuggestionsRequest) {
      this.props.onSuggestionsRequest(value, suggestions => {
        this.setState({
          suggestions
        })
      })
    } else {
      this.setState({
        suggestions: this.getSuggestions(value)
      })
    }
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    return inputLength === 0
      ? this.props.suggestions || []
      : (this.props.suggestions || []).filter(suggestion => {
          const keep =
            count < 5 &&
            suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
          if (keep) {
            count += 1
          }
          return keep
        })
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query)
    const parts = parse(suggestion.name, matches)

    const { numberBadge, classes } = this.props
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div className={classes.suggestionText}>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={index} style={{ fontWeight: 300 }}>
                {part.text}
              </span>
            ) : (
              <strong key={index} style={{ fontWeight: 500 }}>
                {part.text}
              </strong>
            )
          })}
        </div>
        {numberBadge && (
          <Badge
            classes={{ badge: classes.suggestionNumberColor }}
            className={classes.suggestionNumber}
            badgeContent={suggestion.number}
            color="default"
          >
            <span />
          </Badge>
        )}
      </MenuItem>
    )
  }
  shouldRenderSuggestions = () => {
    return this.props.openOnFocus
  }

  render() {
    const {
      classes,
      suggestions,
      onSuggestionSelected,
      onSuggestionsRequest,
      shouldRenderSuggestions,
      numberBadge,
      openOnFocus,
      className,
      inputClassName,
      maxWidthDropdown,
      ...other
    } = this.props
    return (
      <Autosuggest
        className={classNames(className)}
        theme={{
          container: classNames(
            classes.container,
            maxWidthDropdown && classes.containerMaxWidth
          ),
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        shouldRenderSuggestions={shouldRenderSuggestions}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          classes,
          inputClassName,
          ...other
        }}
      />
    )
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Autocomplete)
