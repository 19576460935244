import React from 'react'
import moment from 'moment'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import withTranslate from 'Root/app/withTranslate'

const styles = () => ({
  input: {
    paddingTop: 3
  },
  root: {
    height: 29
  }
})

const DateField = ({
  classes,
  input,
  label,
  meta: { touched, error },
  t,
  getEntityCaption,
  ...other
}) => {
  const { value, onChange, name } = input
  const handleChange = e => {
    //console.log('datum', e)
    //const date = moment(e, 'D-M-YYYY', true)
    //if (date.isValid()) {
    onChange(e)
    //}
  }
  // const handleInputChange = e => {
  //   const date = moment(e.target.value, 'D-M-YYYY', true)
  //   if (date.isValid()) {
  //     onChange(date)
  //   }
  // }

  return (
    <KeyboardDatePicker
      error={touched && !!error}
      name={name}
      fullWidth
      InputProps={{ classes: { input: classes.input, root: classes.root } }}
      label={label}
      value={value || null}
      onChange={handleChange}
      minDateMessage={t('common.form.dateField.minDateMessage', {
        value: other.disablePast
          ? moment().format('D-M-YYYY')
          : other.minDate
          ? moment(other.minDate).format('D-M-YYYY')
          : ''
      })}
      maxDateMessage={t('common.form.dateField.maxDateMessage', {
        value: other.disableFuture
          ? moment().format('D-M-YYYY')
          : other.maxDate
          ? moment(other.maxDate).format('D-M-YYYY')
          : ''
      })}
      invalidLabel=""
      invalidDateMessage={
        value ? t('common.form.dateField.invalidDateMessage') : ''
      }
      cancelLabel={t('common.form.dateField.cancelLabel')}
      clearLabel={t('common.form.dateField.clearLabel')}
      format="D-M-YYYY"
      FormHelperTextProps={{ error: true }}
      {...other}
    />
  )
}

export default compose(
  withTranslate,
  withStyles(styles)
)(DateField)
