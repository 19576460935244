import * as types from './types'
import { createAction } from 'Utils/redux'

export const deselectItem = createAction(types.TABLE_DESELECT_ITEM, 'id')
export const insertItem = createAction(
  types.TABLE_INSERT_ITEM,
  'entityType',
  'data'
)
export const load = createAction(types.TABLE_LOAD_INIT, 'skip', 'filter')
export const loadSucceed = createAction(types.TABLE_LOAD, 'data')
export const reset = createAction(types.TABLE_RESET)
export const selectItem = createAction(
  types.TABLE_SELECT_ITEM,
  'id',
  'multiSelect'
)
export const setInitdata = createAction(types.TABLE_SET_INITDATA)
export const setLoading = createAction(types.TABLE_SET_LOADING, 'loading')
export const setSkip = createAction(types.TABLE_SET_SKIP, 'value')
export const setScrollTop = createAction(types.TABLE_SET_SCROLLTOP, 'scrollTop')
export const setSearchText = createAction(
  types.TABLE_SET_SEARCH_TEXT,
  'searchText'
)
export const setSelected = createAction(types.TABLE_SET_SELECTED, 'selected')
export const setType = createAction(types.TABLE_SET_TYPE, 'entityType')
export const sortSelected = createAction(types.TABLE_SORT_SELECTED)
