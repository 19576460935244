import { call, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { getInspectionsSucceed } from 'Actions/vehicle'
import { globalError } from './shell'

export function* getInspectionsSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/vehicles/${id}/inspections`)
  if (ok) {
    yield put(getInspectionsSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}
