import React, { Component } from 'react'
import compose from 'recompose/compose'
import numeral from 'numeral'
import moment from 'moment'
import { connect } from 'react-redux'
import AppRouter from '../routes/AppRouter'
import { loadLookups } from 'Actions/cache'
import { loadLanguages } from 'Actions/lookup'

class IntlApp extends Component {
  componentDidMount() {
    this.setLanguage()
  }

  componentDidUpdate(prevProps) {
    this.setLanguage(prevProps)
  }

  setLanguage(prevProps = {}) {
    const { isAuthenticated, language, loadLanguages } = this.props
    if (language !== prevProps.language) {
      loadLanguages()
    }
    if (
      (language !== prevProps.language ||
        isAuthenticated !== prevProps.isAuthenticated) &&
      isAuthenticated
    ) {
      this.props.loadLookups()
      numeral.locale(language)
      moment.locale(language)
    }
  }

  render() {
    return <AppRouter />
  }
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.auth.info.authenticated,
      language: state.app.language,
    }),
    { loadLanguages, loadLookups }
  )
)(IntlApp)
