import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const authorisedReducer = createReducer(initialState.authorised, {
  [types.AUTHORISED_GET_BRANDS_SUCCEED]: (state, action) => ({
    ...state,
    brands: action.data,
  }),
  [types.AUTHORISED_GET_LIST_SUCCEED]: (state, action) => ({
    ...state,
    list: action.data,
  }),
})

export default authorisedReducer
