import history from 'Utils/history'
import numeral from 'numeral'
import { call, delay, put, select, take } from 'redux-saga/effects'
import { isDirty, isValid, submit } from 'redux-form'
import { setDelimiters, setLanguage, setVersion } from 'Actions/app'
import { confirmSaga, messageSaga } from './shell'
import { t } from 'Root/app/IntlProvider'

export function* changeLanguageSaga(action) {
  const { language } = action
  window.localStorage.setItem('language', language)
  window.recaptchaOptions = {
    useRecaptchaNet: true,
  }
  yield put(setLanguage(language))
  const delimiters = numeral.locales[language].delimiters
  yield put(setDelimiters(delimiters.decimal, delimiters.thousands))
}

export function* gotoUrlSaga(action) {
  const state = yield select()
  const dirty = isDirty(state.entity.info.entityType)(state)
  if (dirty) {
    const ok = yield call(confirmSaga, {
      title: t('entity.message.saveChanges.title'),
      content: t('entity.message.saveChanges.content'),
    })
    if (ok) {
      const valid = isValid(state.entity.info.entityType)(state)
      if (valid) {
        yield put(submit(state.entity.info.entityType))
        yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
      } else {
        yield delay(500)
        yield call(messageSaga, {
          title: t('entity.message.invalidChanges.title'),
          content: t('entity.message.invalidChanges.content'),
        })
        return
      }
    }
  }
  const { resolve, url } = action
  yield call(history.push, url)
  if (resolve) {
    yield call(resolve)
  }
}

export function* startVersionCheckSaga() {
  while (true) {
    try {
      const response = yield fetch('/version')
      const newVersion = yield response.text()
      const state = yield select()
      const oldVersion = state.app.version
      if (oldVersion === null) {
        yield put(setVersion(newVersion))
      } else if (oldVersion !== newVersion) {
        yield put(setVersion(newVersion))
        window.location.reload(true)
      }
    } catch (e) {}
    // check every 30 minutes
    yield delay(1000 * 60 * 30)
  }
}
