export const getLookupName = (list, value) => {
  if (list && list[value]) {
    return list[value].name
  } else {
    return ''
  }
}

export const getLookupObject = (list, value) => {
  if (list && list[value]) {
    return {
      ...list[value],
    }
  } else {
    return null
  }
}

export const getLookupIconObject = (list, value) => {
  if (list && list[value]) {
    return {
      color: list[value].color,
      icon: list[value].icon,
    }
  } else {
    return null
  }
}

export const getNewPositions = (list, previousIndex, newIndex) => {
  const array = toArray(list).slice(0)
  if (newIndex >= list.length) {
    let k = newIndex - array.length
    while (k-- + 1) {
      array.push(undefined)
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0])
  return array.map((x) => x.id)
}

export const hasIcons = (list) => {
  if (list) {
    return toArray(list).some((item) => item.icon)
  } else {
    return false
  }
}

export const toArray = (items = {}) => {
  let result = []
  if (!items) {
    return result
  }
  if (Array.isArray(items)) {
    result = items.map((item) => {
      const { name, order, ...rest } = item
      return {
        description: name,
        order: 0,
        ...rest,
      }
    })
  } else {
    result = Object.entries(items).map((entry) => {
      const { name, ...rest } = entry[1]
      return {
        id: entry[0],
        description: name,
        ...rest,
      }
    })
  }
  return result.sort((a, b) => {
    if (a.order === 0 && b.order === 0) {
      if (a.description < b.description) return -1
      if (a.description > b.description) return 1
      return 0
    } else {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1
      return 0
    }
  })
}

export const toObjects = (items = []) => {
  const result = {}
  for (const item of items) {
    result[item.id] = {
      id: item.id,
      entity: item.entity,
      name: item.name,
      order: item.order || 0,
      system: false,
      icon: item.icon,
      color: item.color,
      child: item.child,
      language: item.language,
      options: item.options,
      mutation: {
        deleted: item.deleted,
      },
    }
  }
  return result
}
