import { call, delay, put } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { showMessage } from 'Actions/shell'
import { getAttendances, getAttendancesSucceed } from 'Actions/attendance'
import { globalError } from './shell'
import { messageLevel } from 'Utils/constants'
import { t } from 'Root/app/IntlProvider'

export function* checkTokenSaga(action) {
  const {
    payload: { id, token, resolve, reject },
  } = action
  const {
    ok,
    data,
  } = yield http.post(`/attendances/employee/${id}/check-token`, { token })
  if (ok) {
    yield call(resolve)
  } else if (data.error == 'Employee not found') {
    yield put(
      showMessage(t('attendance.token.error.notFound'), messageLevel.error)
    )
    yield call(reject)
  } else if (data.error == 'No secret') {
    yield put(
      showMessage(t('attendance.token.error.noSecret'), messageLevel.error)
    )
    yield call(reject)
  } else if (data.error == 'Code not valid') {
    yield put(
      showMessage(t('attendance.token.error.invalid'), messageLevel.error)
    )
    yield call(reject)
  } else {
    yield call(globalError, data, reject)
  }
}

export function* getList() {
  const { ok, error, data } = yield http.get('/attendances/clock')
  if (ok) {
    yield put(getAttendancesSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* register(action) {
  const { id, image } = action
  const { ok, data } = yield http.post(`/attendances/employee/${id}`, { image })
  if (ok) {
    yield put(getAttendances())
  } else {
    yield call(globalError, data)
  }
}

export function* startRefreshing() {
  while (true) {
    try {
      const { ok, data } = yield http.get('/attendances/clock')
      if (ok) {
        yield put(getAttendancesSucceed(data))
      }
    } catch (e) {}
    // check every 15 minutes
    yield delay(1000 * 60 * 15)
  }
}
