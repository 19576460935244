import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'

import history from 'Utils/history'
import Root from 'Components/shell/Root'
import PrivateRoute from './PrivateRoute'
import Signin from 'Components/auth/Signin'
import { changeLanguage } from 'Actions/app'

const styles = (theme) => {
  return {
    '@global': {
      html: {
        background: theme.palette.background.default,
        WebkitFontSmoothing: 'antialiased', // Antialiasing.
        MozOsxFontSmoothing: 'grayscale', // Antialiasing.
        boxSizing: 'border-box',
        '@media print': {
          background: theme.palette.common.white,
        },
      },
      '*, *:before, *:after': {
        boxSizing: 'inherit',
      },
      '.public-DraftEditorPlaceholder-root': {
        color: 'currentColor',
        opacity: theme.palette.type === 'light' ? 0.42 : 0.5,
      },
      body: {
        margin: 0,
        listStyle: 'none',
      },
      a: {
        textDecoration: 'none',
        color:
          theme.palette.type === 'light'
            ? theme.palette.primary.main
            : theme.palette.primary.light,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      ul: {
        padding: 0,
        paddingLeft: 20,
        margin: 0,
      },
    },
  }
}

class AppRouter extends Component {
  componentDidMount() {
    const { changeLanguage, language } = this.props
    changeLanguage(language)
  }
  render() {
    const { frame } = this.props
    return (
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        <div style={{ display: frame ? 'unset' : 'none' }}>
          <Switch>
            <Route path="/signin" component={Signin} />
            <PrivateRoute path="/" component={Root} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default compose(
  injectIntl,
  withStyles(styles),
  connect(
    (state) => ({ frame: state.app.frame, language: state.app.language }),
    {
      changeLanguage,
    }
  )
)(AppRouter)
