const id = (name) => `@repairroom/${name}`

// app
export const APP_CHANGE_LANGUAGE = id('app_change_language')
export const APP_DOCK_NAVBAR = id('app_dock_navbar')
export const APP_FOLD_PANELS = id('app_fold_panels')
export const APP_GOTO_URL = id('app_goto_url')
export const APP_OPEN_NAVBAR = id('app_open_navbar')
export const APP_REGISTER_PANEL = id('app_register_panel')
export const APP_SET_DELIMITERS = id('app_set_delimiters')
export const APP_SET_LANGUAGE = id('app_set_language')
export const APP_SET_PANEL_EXPANDED = id('app_set_panel_expanded')
export const APP_SET_VERSION = id('app_set_version')
export const APP_SHOW_NEW_VERSION = id('app_show_new_version')
export const APP_START_VERSION_CHECK = id('app_start_version_check')
export const APP_UNFOLD_PANELS = id('app_unfold_panels')

// attendance
export const ATTENDANCE_CHECK_TOKEN = id('attendance_check_token')
export const ATTENDANCE_GET = id('attendance_get')
export const ATTENDANCE_GET_SUCCEED = id('attendance_get_succeed')
export const ATTENDANCE_REGISTER = id('attendance_register')
export const ATTENDANCE_START_REFRESHING = id('attendance_start_refreshing')

// auth
export const AUTH_ACTIVATE = id('auth_activate')
export const AUTH_ACTIVATE_INFO = id('auth_activate_info')
export const AUTH_ACTIVATE_INFO_SUCCEED = id('auth_activate_info_succeed')
export const AUTH_CHANGE_PASSWORD = id('auth_change_password')
export const AUTH_CHECK_EMAIL = id('auth_check_email')
export const AUTH_CHECK_PASSWORD = id('auth_check_password')
export const AUTH_COMPANY_GET = id('auth_company_get')
export const AUTH_COMPANY_PUT = id('auth_company_put')
export const AUTH_COMPANY_SET_DATA = id('auth_company_set_data')
export const AUTH_GET_COMPANIES = id('auth_get_companies')
export const AUTH_GET_QRCODE = id('auth_get_qrcode')
export const AUTH_SESSION = id('auth_session')
export const AUTH_SESSION_INIT = id('auth_init_session')
export const AUTH_SET_COMPANIES = id('auth_set_companies')
export const AUTH_SET_QRCODE = id('auth_set_qrcode')
export const AUTH_SET_TWOFACTOR_ENABLED = id('auth_set_twofactor_enabled')
export const AUTH_SIGNIN = id('auth_signin')
export const AUTH_SIGNIN_SUCCEED = id('auth_signin_succeed')
export const AUTH_SIGNOUT = id('auth_signout')
export const AUTH_SIGNOUT_SUCCEED = id('auth_signout_succeed')
export const AUTH_SWITCH_COMPANY = id('auth_switch_company')
export const AUTH_TWOFACTOR_ENABLE = id('auth_twofactor_enable')
export const AUTH_USER_GET = id('auth_user_get')
export const AUTH_USER_PUT = id('auth_user_put')
export const AUTH_USER_PUT_THEME = id('auth_user_put_theme')
export const AUTH_USER_SET_DATA = id('auth_user_set_data')
export const AUTH_VALIDATE_TOKEN = id('auth_validate_token')

// autorised
export const AUTHORISED_CREATE = id('authorized_get_create')
export const AUTHORISED_GET_BRANDS = id('authorized_get_brands')
export const AUTHORISED_GET_BRANDS_SUCCEED = id('authorized_get_brands_succeed')
export const AUTHORISED_GET_LIST = id('authorized_get_list')
export const AUTHORISED_GET_LIST_SUCCEED = id('authorized_get_list_succeed')
export const AUTHORISED_REMOVE = id('authorised_remove')

// cache
export const CACHE_ADD_LOOKUPS = id('cache_add_lookups')
export const CACHE_LOAD_LOOKUPS = id('cache_load_lookups')

// employee
export const EMPLOYEE_GET_QRCODE = id('employee_get_qrcode')

// entity
export const ENTITY_GET = id('entity_get')
export const ENTITY_PUT = id('entity_put')
export const ENTITY_MARK = id('entity_mark')
export const ENTITY_RESET_DATA = id('entity_reset_data')
export const ENTITY_RESTORE = id('entity_restore')
export const ENTITY_SET_CONTENTOVERFLOWX = id('entity_set_contentoverflowx')
export const ENTITY_SET_CONTENTOVERFLOWY = id('entity_set_contentoverflowy')
export const ENTITY_SET_DATA = id('entity_set_data')
export const ENTITY_SET_EDITFORM = id('entity_set_editform')
export const ENTITY_SET_EDITMODE = id('entity_set_editmode')
export const ENTITY_SET_ID = id('entity_set_id')
export const ENTITY_SET_LOADED_OK = id('entity_set_loaded_ok')
export const ENTITY_SET_LOADING = id('entity_set_loading')
export const ENTITY_SET_PREVLOCATION = id('entity_set_prevlocation')
export const ENTITY_SET_TAB_CAPTION = id('entity_set_tab_caption')
export const ENTITY_SET_TAB_COUNT = id('entity_set_tab_count')
export const ENTITY_SET_TYPE = id('entity_set_type')
export const ENTITY_UPDATE_DATA = id('entity_update_data')

// form
export const FORM_UPDATE_FIELD = '@@redux-form/CHANGE'

// lookup
export const LOOKUP_LOAD_LANGUAGES = id('lookup_load_languages')
export const LOOKUP_SET_LANGUAGES = id('lookup_set_languages')

// repairorder
export const REPAIR_ORDER_UPLOAD = id('repair_order_upload')

// shell
export const SHELL_CONFIRM = id('shell_confirm')
export const SHELL_CONFIRM_NO = id('shell_confirm_no')
export const SHELL_CONFIRM_YES = id('shell_confirm_yes')
export const SHELL_MESSAGE = id('shell_message')
export const SHELL_MESSAGE_SHOW = id('shell_message_show')
export const SHELL_MESSAGE_HIDE = id('shell_message_hide')

// table
export const TABLE_DESELECT_ITEM = id('table_deselect_item')
export const TABLE_INSERT_ITEM = id('table_insert_item')
export const TABLE_LOAD = id('table_load')
export const TABLE_LOAD_INIT = id('table_load_init')
export const TABLE_RESET = id('table_reset')
export const TABLE_SELECT_ITEM = id('table_select_item')
export const TABLE_SET_INITDATA = id('table_set_initdata')
export const TABLE_SET_LOADING = id('table_set_loading')
export const TABLE_SET_SCROLLTOP = id('table_set_scrolltop')
export const TABLE_SET_SEARCH_TEXT = id('table_set_search_text')
export const TABLE_SET_SELECTED = id('table_set_selected')
export const TABLE_SET_SKIP = id('table_set_skip')
export const TABLE_SET_TYPE = id('table_set_type')
export const TABLE_SORT_SELECTED = id('table_sort_selected')

// theme
export const THEME_SET_THEME = id('theme_set_theme')

// vehicle
export const VEHICLE_GET_INSPECTIONS = id('vehicle_get_inspections')
export const VEHICLE_GET_INSPECTIONS_SUCCEED = id(
  'vehicle_get_inspections_succeed'
)

// workorder
export const WORK_ORDER_ADD = id('work_order_add')
