import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '@material-ui/core/SvgIcon'

const SvgIconCustom = global.__MUI_SvgIcon__ || SvgIcon

let Play = props => (
  <SvgIconCustom {...props}>
    <path d="M8 5.14v14l11-7-11-7z" />
  </SvgIconCustom>
)

Play = pure(Play)
Play.muiName = 'SvgIcon'

export default Play
