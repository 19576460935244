import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'

import Snackbar from '@material-ui/core/Snackbar'
import { amber, green, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { hideMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'

const styles = theme => ({
  message: {
    fontWeight: 'normal'
  },
  messageError: {
    backgroundColor: red[500]
  },
  messageInfo: {
    backgroundColor: theme.palette.primary.main
  },
  messageSuccess: {
    backgroundColor: green[500]
  },
  messageWarning: {
    backgroundColor: amber[500]
  }
})

class Message extends Component {
  handleSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.props.hideMessage()
  }
  render = () => {
    const {
      classes,
      duration,
      error,
      info,
      success,
      text,
      visible,
      warning
    } = this.props
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={visible || false}
        disableWindowBlurListener
        ContentProps={{
          className: classNames(classes.message, {
            [classes.messageError]: error,
            [classes.messageInfo]: info,
            [classes.messageSuccess]: success,
            [classes.messageWarning]: warning
          })
        }}
        message={text || ''}
        autoHideDuration={duration}
        onClose={this.handleSnackbarClose}
      />
    )
  }
}

export default compose(
  connect(
    state => ({
      duration: state.shell.message.duration,
      error: state.shell.message.level === messageLevel.error,
      info: state.shell.message.level === messageLevel.info,
      success: state.shell.message.level === messageLevel.success,
      text: state.shell.message.text,
      visible: state.shell.message.visible,
      warning: state.shell.message.level === messageLevel.warning
    }),
    { hideMessage }
  ),
  withStyles(styles)
)(Message)
