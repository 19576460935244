import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from 'redux-modal'

import appReducer from './appReducer'
import attendanceReducer from './attendanceReducer'
import authReducer from './authReducer'
import authorisedReducer from './authorisedReducer'
import cacheReducer from './cacheReducer'
import entityReducer from './entityReducer'
import shellReducer from './shellReducer'
import tableReducer from './tableReducer'
import themeReducer from './themeReducer'
import vehicleReducer from './vehicleReducer'

const rootReducer = () =>
  combineReducers({
    app: appReducer,
    attendance: attendanceReducer,
    auth: authReducer,
    authorised: authorisedReducer,
    cache: cacheReducer,
    entity: entityReducer,
    form: formReducer,
    modal: modalReducer,
    shell: shellReducer,
    table: tableReducer,
    theme: themeReducer,
    vehicle: vehicleReducer,
  })

export default rootReducer
